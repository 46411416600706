import { useTerminateConversationControllerTerminateConversationMutation } from '@shared/services/apiService/apiService';

export const useTerminateConversation = (recipientId: string) => {
  const [useTerminateConversation, { isLoading }] =
    useTerminateConversationControllerTerminateConversationMutation();

  const terminate = () => {
    try {
      useTerminateConversation({ id: recipientId });
    } catch (error) {
      console.log(error);
    }
  };

  return { terminate };
};
