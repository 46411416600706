import { POLLING_INTERVAL } from '@shared/constants/conversation';
import { useGetConversationMessageControllerGetMessageQuery } from '@shared/services/conversationApiService/apiService';
import { useCallback, useState } from 'react';

export const useGetConversation = (recipientId: string) => {
  const [pollingConversationInterval, setPollingConversationInterval] = useState(POLLING_INTERVAL);
  const [cursor, setCursor] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const limit = 20;

  const { data: conversationData, refetch: conversationRefetch } =
    useGetConversationMessageControllerGetMessageQuery({
      conversationId: recipientId,
      limit: limit,
      cursor: cursor!,
    });

  const loadMoreMessages = useCallback(() => {
    if (hasMore) {
      conversationRefetch().then((result) => {
        if (result.data?.meta.hasMore) {
          setCursor(result.data?.meta.cursor);
        } else {
          setHasMore(false);
        }
      });
    }
  }, [cursor, hasMore, conversationRefetch]);

  return {
    conversationData,
    conversationRefetch,
    pollingConversationInterval,
    setPollingConversationInterval,
    loadMoreMessages,
    hasMore,
  };
};
