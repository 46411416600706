import { GetAllCampaignsControllerGetAllCampaignsApiResponse } from '@shared/services/apiService/apiService';

export function runningCampaignsCount(
  campaignList: GetAllCampaignsControllerGetAllCampaignsApiResponse | undefined,
) {
  let activeCampaigns = campaignList?.filter(
    (campaign) => campaign?.campaignRun?.status == 'active',
  );
  return `${activeCampaigns?.length}`;
}

export function inactiveCampaignsCount(
  campaignList: GetAllCampaignsControllerGetAllCampaignsApiResponse | undefined,
) {
  let inactiveCampaigns = campaignList?.filter(
    (campaign) => campaign?.campaignRun?.status == 'inactive',
  );

  let campaignWithoutStatus = campaignList?.filter(
    (campaign) => campaign?.campaignRun?.status === undefined,
  );

  return (inactiveCampaigns?.length || 0) + (campaignWithoutStatus?.length || 0);
}

export function ongoingCallCount(
  campaignList: GetAllCampaignsControllerGetAllCampaignsApiResponse | undefined,
) {
  let count = campaignList?.reduce((total, campaign) => total + campaign.ongoingCallCount || 0, 0);

  return count || 0;
}
