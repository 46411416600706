import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { capitalize } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconTrash, IconExternalLink, IconArrowsSort, IconRefresh } from '@tabler/icons-react';

import {
  CampaignWithDetailedInfoDto,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useStopCampaignRunControllerStopCampaignRunMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import { ResponseMsg } from '@shared/types/admin/agent';
import BlankCard from '@shared/components/shared/BlankCard';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { CampaignListProps, SortingCampaignCoulmns } from '@shared/types/admin/campaign';
import CustomAlert from '@shared/components/alert/alert';

import { useStyles } from './styles';
import CampaignStatusButton, { CampaignStatus } from './CampaignStatusButton/CampaignStatusButton';
import Spinner from '@shared/components/spinner/Spinner';
import { ConfirmDeleteDialog } from '@shared/components/confirmDeleteDialog/ConfirmDeleteDialog';

export const CampaignList: React.FC<CampaignListProps> = ({
  setLoading,
  campaignList,
  onCampaignStatusUpdate,
}: CampaignListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigator = useNavigate();
  const [useDeleteCampaign] = useDeleteCampaignControllerDeleteCampaignMutation();

  const [useRunCampaign, { isLoading: isRunningLoading }] =
    useCreateCampaignRunControllerCreateCampaignRunMutation();
  const [useStopCampaign, { isLoading: isStoppingLoading }] =
    useStopCampaignRunControllerStopCampaignRunMutation();

  const { refetch } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const [campaigns, setCampaigns] = useState<CampaignWithDetailedInfoDto[]>([]);
  const [sortOrder, setSortOrder] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });
  const [deleteId, setDeleteId] = useState<string | null>();
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [responseLoadingFlag, setResponseLoadingFlag] = useState(false);

  useEffect(() => {
    setCampaigns(campaignList);
  }, [campaignList]);

  const handleSort = (field: SortingCampaignCoulmns) => {
    setSortOrder(!sortOrder);
    const sortedCampaigns = sortArray(campaigns, field, sortOrder);
    setCampaigns(sortedCampaigns);
  };

  const sortArray = (
    arr: CampaignWithDetailedInfoDto[],
    field: SortingCampaignCoulmns,
    ascending = true,
  ) => {
    return [...arr].sort((a: CampaignWithDetailedInfoDto, b: CampaignWithDetailedInfoDto) => {
      if (a[field] < b[field]) return ascending ? -1 : 1;
      if (a[field] > b[field]) return ascending ? 1 : -1;
      return 0;
    });
  };

  const statusBadge = (status: boolean) => {
    return status === true ? theme.palette.success.light : theme.palette.error.light;
  };

  const handleManagedStartStop = async (id: string, campaignStatus: CampaignStatus) => {
    if (CampaignStatus.NotStarted === campaignStatus) {
      await useRunCampaign({ id });
    } else if (CampaignStatus.Dialing === campaignStatus) {
      await useStopCampaign({ id });
    }

    onCampaignStatusUpdate();
  };

  const handleDeleteBtn = (id: any) => {
    setShowDialog(true);
    if (selectedRowId) {
      setDeleteId(selectedRowId);
    }
    setAnchorEl(null);
  };

  const deleteCampagin = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoading(true);
      const response = await useDeleteCampaign({
        id: deleteId,
      });
      if ('error' in response) {
        setResponseMsg({
          message: 'Failed to delete agent!',
          type: 'error',
        });
      } else {
        setResponseMsg({
          message: 'Deleted agent successfully.',
          type: 'success',
        });
      }
    }
    setDeleteId('');
    setLoading(false);
  };

  const handleMoreBtn = (event: any, rowId: string) => {
    setAnchorEl(event.currentTarget);

    setSelectedRowId(rowId);
  };

  const handlePopoverAction = () => {
    if (selectedRowId) {
      handleRowDoubleClick(selectedRowId);
    }
    handleClosePopover();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRowDoubleClick = (rowId: string) => {
    navigator(`/admin/campaign/edit?id=${rowId}`);
  };

  const handleRefresh = async () => {
    setResponseLoadingFlag(true);
    await refetch();
    setResponseLoadingFlag(false);
  };

  if (responseLoadingFlag) {
    return <Spinner />;
  }

  return (
    <BlankCard className={classes.campaign}>
      <Box className={classes.campaign_header}>
        <Box className={classes.campaign_header_title}>
          <Typography fontSize="20px">{t('adminMainPage.campaigns.title')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignContent: 'start', alignItems: 'start' }}>
          {campaignList.length !== 0 && (
            <Tooltip title={t('adminMainPage.operation.refresh')} arrow>
              <Button
                sx={{ marginRight: '10px' }}
                color="primary"
                variant="contained"
                startIcon={<IconRefresh size="24" color="#fff" />}
                onClick={handleRefresh}>
                {t('adminMainPage.operation.refresh')}
              </Button>
            </Tooltip>
          )}
          <Tooltip title={t('adminMainPage.operation.addCampaign')} arrow>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigator(AuthenticatedPath.CREATE_CAMPAIGN);
              }}>
              {t('adminMainPage.operation.addCampaign')}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer>
        <Table className={classes.campaign_table}>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort('title')}
                className={classes.campaign_table_first_cell}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.campaignName')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.status')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('totalLeads')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.totalNumber')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.processedNumber')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('pendingLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.ongoingCalls')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('reachedLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.reachedCall')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('unreachedLeadsCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.unreachedCall')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell onClick={() => handleSort('plannedRecallCount')}>
                <Box className={classes.campagin_table_sort_cell}>
                  <Typography variant="h6" className={classes.campagin_table_th}>
                    {t('adminMainPage.campaigns.plannedRetries')}
                  </Typography>
                  <IconArrowsSort size="12" />
                </Box>
              </TableCell>

              <TableCell>
                <Typography variant="h6" className={classes.campagin_table_th} align="center">
                  {t('adminMainPage.operation.startStop')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" className={classes.campagin_table_th} align="center">
                  {t('adminMainPage.operation.action')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((row) => (
              <TableRow
                className={classes.campaign_table_row}
                key={row.id}
                hover
                onDoubleClick={() => {
                  handleRowDoubleClick(row.id);
                }}>
                <TableCell className={classes.campaign_table_first_cell}>
                  <Typography variant="subtitle1">{row.title}</Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    sx={{
                      backgroundColor: statusBadge(
                        row.campaignRun?.status === 'active' ? true : false,
                      ),
                    }}
                    size="small"
                    label={capitalize(row.campaignRun?.status || 'inactive')}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.totalLeads}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">
                    {row.unreachedLeadsCount + row.reachedLeadsCount}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.pendingLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.reachedLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.unreachedLeadsCount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{row.plannedRecallCount}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Status Campaign">
                    <CampaignStatusButton
                      onClick={handleManagedStartStop}
                      disabled={isRunningLoading || isStoppingLoading}
                      campaign={row}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="More actions">
                    <IconButton
                      aria-describedby={row.id.toString()}
                      onClick={(event) => {
                        handleMoreBtn(event, row.id);
                      }}>
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <Popover
                  id={row.id.toString()}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <Box className={classes.agent_table_popover}>
                    <IconButton
                      className={classes.agent_table_popover_button}
                      onClick={handlePopoverAction}>
                      <IconExternalLink size="24px" />
                      <Typography>{t('adminMainPage.operation.open')}</Typography>
                    </IconButton>
                    <IconButton
                      className={classes.agent_table_popover_button}
                      onClick={() => {
                        handleDeleteBtn(row.id);
                      }}>
                      <IconTrash size="24px" color="#FA896B" />
                      <Typography color="#FA896B">{t('adminMainPage.operation.delete')}</Typography>
                    </IconButton>
                  </Box>
                </Popover>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {responseMsg.message ? (
          <CustomAlert message={responseMsg.message} type={responseMsg.type} />
        ) : (
          ''
        )}
      </div>
      <ConfirmDeleteDialog
        open={showDialog}
        onClose={setShowDialog}
        dialogTitle={'dialog.title3'}
        dialogContentText={'dialog.content3'}
        showDialog={setShowDialog}
        confirmDelete={deleteCampagin}
      />
    </BlankCard>
  );
};
