import { CallScheduleRequestDto } from '@shared/services/apiService/apiService';

interface ScheduleState extends CallScheduleRequestDto {}

export const startTime = '09:00:00';
export const endTime = '18:00:00';

export const initialSchedule: ScheduleState = {
  mondayStart: startTime,
  mondayEnd: endTime,
  tuesdayStart: startTime,
  tuesdayEnd: endTime,
  wednesdayStart: startTime,
  wednesdayEnd: endTime,
  thursdayStart: startTime,
  thursdayEnd: endTime,
  fridayStart: startTime,
  fridayEnd: endTime,
  saturdayStart: startTime,
  saturdayEnd: endTime,
  sundayStart: startTime,
  sundayEnd: endTime,
};
