import { baseApi as api } from '../../store/api/conversationApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appControllerGetHello: build.query<
      AppControllerGetHelloApiResponse,
      AppControllerGetHelloApiArg
    >({
      query: () => ({ url: `/` }),
    }),
    logConversationMessageControllerLogMessage: build.mutation<
      LogConversationMessageControllerLogMessageApiResponse,
      LogConversationMessageControllerLogMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation-logs`,
        method: 'POST',
        body: queryArg.logConversationMessageRequestDto,
      }),
    }),
    getConversationMessageControllerGetMessage: build.query<
      GetConversationMessageControllerGetMessageApiResponse,
      GetConversationMessageControllerGetMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/conversation-logs`,
        params: {
          limit: queryArg.limit,
          cursor: queryArg.cursor,
          conversationId: queryArg.conversationId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiService };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type LogConversationMessageControllerLogMessageApiResponse =
  /** status 201  */ LogConversationMessageResponseDto;
export type LogConversationMessageControllerLogMessageApiArg = {
  logConversationMessageRequestDto: LogConversationMessageRequestDto;
};
export type GetConversationMessageControllerGetMessageApiResponse =
  /** status 200 Successfully received model list */ CursorPaginationDto & {
    data?: ConversationLogDto[];
  };
export type GetConversationMessageControllerGetMessageApiArg = {
  limit?: number;
  cursor?: string;
  conversationId: string;
};
export type ConversationActorsType = 'agent' | 'callee';
export type ConversationLogDto = {
  id: string;
  conversationId: string;
  actor: ConversationActorsType;
  message: string;
  createdAt: string;
  updatedAt: string;
};
export type LogConversationMessageResponseDto = {
  conversationLog: ConversationLogDto;
};
export type LogConversationMessageRequestDto = {
  companyId: string;
  conversationId: string;
  message: string;
  /** The role of the actor in the conversation. Must be one of the following: agent, callee. */
  actor: 'agent' | 'callee';
  /** Timestamp of the message creation in ISO 8601 format. If not provided, the current date and time will be used as default. */
  timestamp?: string;
};
export type CursorMetaDto = {
  hasMore: boolean;
  cursor: string | null;
  total: number | null;
};
export type CursorPaginationDto = {
  data: any[];
  meta: CursorMetaDto;
};
export const {
  useAppControllerGetHelloQuery,
  useLogConversationMessageControllerLogMessageMutation,
  useGetConversationMessageControllerGetMessageQuery,
} = injectedRtkApi;
