import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { Grid, Typography } from '@mui/material';
import { CampaignFieldsError, FormFields } from '@shared/types/admin/campaign';
import { Control, UseFormSetValue } from 'react-hook-form';
import CustomFormLabel from '../forms/theme-elements/CustomFormLabel';
import CustomTextField from '../forms/theme-elements/CustomTextField';
import { t } from 'i18next';

interface CampaignRetryFields {
  fields: {
    name: string;
    label: string;
    placeholder: string;
    retryFieldError: string[];
    retryFieldValue: string;
    setRetryFieldValue: React.Dispatch<React.SetStateAction<string>>;
  }[];
  setValue: UseFormSetValue<CreateCampaignFormValue>;
  setCampaignFieldError: React.Dispatch<React.SetStateAction<CampaignFieldsError>>;
  control: Control<CreateCampaignFormValue, any>;
}

export const CampaignRetryFields = ({
  fields,
  setValue,
  setCampaignFieldError,
  control,
}: CampaignRetryFields) => {
  const handleOnChangeRetryFieldInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9.]/g, '');
    const parsedValue = parseFloat(filteredValue);

    const field = fields.find((fieldItem) => fieldItem.name === name);

    if (field) {
      field.setRetryFieldValue(filteredValue);
      setCampaignFieldError((prevState) => ({
        ...prevState,
        [name]: [],
      }));
      setValue(name as FormFields, parsedValue);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        {fields.map(({ name, label, retryFieldValue, placeholder, retryFieldError }) => (
          <Grid item xs={12} sm={6} key={name}>
            <CustomFormLabel htmlFor={`form-field-${name}`}>{t(label)}</CustomFormLabel>
            <CustomTextField
              control={control}
              name={name}
              onChange={handleOnChangeRetryFieldInput}
              type="text"
              id={`form-field-${name}`}
              value={retryFieldValue}
              placeholder={placeholder}
              fullWidth
            />
            {retryFieldError && retryFieldError.length > 0 && (
              <Typography component="p" sx={{ color: '#FF0000', maxWidth: '300px' }}>
                {retryFieldError}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
